import React from "react";
import Layout from "../components/Layout";
import MainSection from "../components/CennikComponents/PriceSection";
import { Helmet } from "react-helmet";
export default function Cennik() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Restauracja | Stragona</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <MainSection></MainSection>
    </>
  );
}
